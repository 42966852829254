import { axiosHandler } from "../lib/utils";
import { loadUser, putUser, loadAllUsers } from "../redux/user";
import { loadChildren, addChild, removeChild } from "../redux/parent";
import {
  loadClients,
  addClient,
  removeClient,
  loadAgentNotes,
  addAgentNote,
  removeAgentNote,
  updateAgentNote,
} from "../redux/agent";
import { userTypes } from "../utils/constants";

export const fetchUser = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/${state.user.data?.id}`,
      state,
    });

    if (
      json?.data?.userType === userTypes.AGENT ||
      json?.data?.userType === userTypes.PARENT ||
      json?.data?.userType === userTypes.SPOUSE
    ) {
      await Promise.all([
        dispatch(loadUser(json.data)),
        dispatch(fetchUsers()),
      ]);
    } else {
      await dispatch(loadUser(json.data));
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchUsers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user`,
      state,
    });
    await dispatch(loadAllUsers(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchIndividualUser = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/${id}`,
      state,
    });
    return json.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/${state.user.data?.id}`,
      data,
      method: "PUT",
      state,
    });
    await dispatch(putUser(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePassword = (data) => async (_, getState) => {
  try {
    const state = getState();
    await axiosHandler({
      url: `user/password/${state.user.data?.id}`,
      data,
      method: "PUT",
      state,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateParent = (data) => async (dispatch, getState) => {
  try {
    const state = getState();

    const json = await axiosHandler({
      url: `user/parent/${state.user.data?.id}`,
      data,
      method: "PUT",
      state,
    });

    const assignment = json?.data?.assignment;

    const childExists = state?.parent?.data?.hasOwnProperty(
      assignment?.playerId
    );

    if (childExists) {
      await dispatch(removeChild(assignment));
    } else {
      await dispatch(addChild(assignment));
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateAgent = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/agent/${state.user.data?.id}`,
      data,
      method: "PUT",
      state,
    });

    const assignment = json?.data?.assignment;

    const clientExists = state?.agent?.data?.clients?.hasOwnProperty(
      assignment?.playerId
    );

    if (clientExists) {
      await dispatch(removeClient(assignment));
    } else {
      await dispatch(addClient(assignment));
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchChildren = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/parent/${state.user.data?.id}`,
      state,
    });
    await dispatch(loadChildren(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchClients = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/agent/${state.user.data?.id}`,
      state,
    });
    await dispatch(loadClients(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createAgentNote = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/agent/notes/${state.user.data?.id}`,
      data,
      method: "POST",
      state,
    });
    await dispatch(addAgentNote(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteAgentNote = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/agent/notes/${id}`,
      method: "DELETE",
      state,
    });
    await dispatch(removeAgentNote(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const putAgentNote = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/agent/notes/${data?.id}`,
      data,
      method: "PUT",
      state,
    });
    await dispatch(updateAgentNote(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAgentNotes = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const json = await axiosHandler({
      url: `user/agent/notes/${state.user.data?.id}`,
      state,
    });
    await dispatch(loadAgentNotes(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
