import React, { useMemo, useState } from "react";
import {
  TextField,
  CircularProgress,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import { fetchChildren, fetchUsers, fetchClients } from "../../api/user";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { playerStatusTypes, userTypes } from "../../utils/constants";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRadius: theme.shape.borderRadius,
    "& .MuiTabs-indicator": {
      backgroundColor: "#d32f2f",
      height: 3,
    },
  },
  tab: {
    minWidth: 0,
    fontWeight: 600,
    fontSize: "0.95rem",
    "&.Mui-selected": {
      color: "#d32f2f",
    },
    "&:hover": {
      color: "#d32f2f",
      opacity: 0.8,
    },
  },
}));

const AllUsers = ({
  selected,
  fullWidth,
  isProspects,
  filter: initialFilter = "all",
  onFilterChange,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [activeFilter, setActiveFilter] = useState(initialFilter);
  const { user, loadingUser } = useReduxQuery("User", fetchUsers);

  const handleFilterChange = (_, newValue) => {
    setActiveFilter(newValue);
    if (onFilterChange) {
      onFilterChange(newValue);
    }
  };

  // Always call both hooks regardless of the condition
  const parentQuery = useReduxQuery(
    user?.userType === userTypes.PARENT || user?.userType === userTypes.SPOUSE
      ? "Parent"
      : null,
    fetchChildren
  );
  const agentQuery = useReduxQuery(
    user?.userType === userTypes.AGENT ? "Agent" : null,
    fetchClients
  );

  // Destructure the results with default values if the hooks were not called
  const { parent = {} } = parentQuery;
  const agent = agentQuery?.agent?.clients || {};

  const allPlayers = useMemo(() => {
    let sortedPlayerList = (user?.allUsers || [])
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .filter(
        (player) =>
          player.userType === userTypes.PLAYER ||
          player.userType === userTypes.COLLEGE
      );

    if (selected) {
      if (
        user?.userType === userTypes.PARENT ||
        user?.userType === userTypes.SPOUSE
      ) {
        sortedPlayerList = sortedPlayerList.filter(
          (player) => parent[player.id]
        );
      } else if (user?.userType === userTypes.AGENT) {
        sortedPlayerList = sortedPlayerList
          .filter((player) => agent[player.id])
          .filter((player) =>
            isProspects
              ? player.status === playerStatusTypes.PROSPECT
              : player.status !== playerStatusTypes.PROSPECT
          );
      }
    }

    // Apply Pro/College filter
    if (activeFilter !== "all") {
      sortedPlayerList = sortedPlayerList.filter((player) =>
        activeFilter === "pro"
          ? player.userType === userTypes.PLAYER
          : player.userType === userTypes.COLLEGE
      );
    }

    return sortedPlayerList;
  }, [
    user?.allUsers,
    user?.userType,
    selected,
    parent,
    isProspects,
    agent,
    activeFilter,
  ]);

  const filteredPlayers = useMemo(() => {
    return allPlayers.filter((player) =>
      `${player.firstName} ${player.lastName}`
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  }, [allPlayers, value]);

  const navigateToPlayer = (player) => {
    history.push({
      pathname: `${
        user?.userType === userTypes.AGENT ? "Agent/" : ""
      }Contract/${player.id}`,
      selectedUser: player,
      parent:
        user?.userType === userTypes.PARENT ||
        user?.userType === userTypes.SPOUSE,
    });
  };

  const statusColors = {
    Active: { color: "green" },
    FreeAgent: { color: "blue" },
    InjuredReserve: { color: "red" },
    Retired: { color: "gray" },
    PUP: { color: "orange" },
    NFI: { color: "orange" },
    PracticeSquad: { color: "purple" },
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={activeFilter}
            onChange={handleFilterChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
            style={{ marginBottom: "16px" }}
          >
            <Tab label="All" value="all" className={classes.tab} />
            <Tab label="Pro" value="pro" className={classes.tab} />
            <Tab label="College" value="college" className={classes.tab} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {loadingUser ? (
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={30} color="inherit" />
            </div>
          </Grid>
        ) : (
          <>
            {allPlayers && allPlayers.length > 0 && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder="Search for a user..."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {filteredPlayers && filteredPlayers.length > 0 ? (
              filteredPlayers.map((player, index) => (
                <Grid
                  item
                  {...(!fullWidth
                    ? { xs: 12 }
                    : { xs: 12, sm: 6, md: 4, lg: 4 })}
                  key={index}
                >
                  <Card
                    key={index}
                    onClick={() => navigateToPlayer(player)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3>{`${player?.firstName} ${player?.lastName}`}</h3>
                      </div>

                      <p>
                        {player?.team
                          ? `${player?.team?.city} ${player?.team?.name}`
                          : "No Team"}
                      </p>
                      {player?.type !== "None" && <p>{player?.type}</p>}
                      <p
                        style={statusColors[player?.status?.replace(/\s/g, "")]}
                      >
                        {player?.status}
                      </p>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {selected && allPlayers?.length === 0 && (
                  <p>
                    {user?.userType === userTypes.PARENT
                      ? "You currently do not have any players selected as children."
                      : user?.userType === userTypes.SPOUSE
                      ? "You currently do not have any players selected as a spouse."
                      : `You currently do not have any players selected as ${
                          isProspects ? "prospects" : "clients"
                        }.`}
                  </p>
                )}
                {(!selected || allPlayers?.length > 0) && (
                  <p>
                    There are currently no players in the database that match
                    your search criteria.
                  </p>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default AllUsers;
