import React, { useMemo } from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import numberFormatter from "../../../utils/numberFormatter";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchUser } from "../../../api/user";

const headers = [
  "COMPARABLE PLAYERS",
  "LENGTH",
  "VALUE",
  "AVG. SALARY",
  "AGE WHEN SIGNED",
  "",
];

const CreateHeader = ({ columns }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, i) =>
          i === 0 ? (
            <TableCell align="left" key={col} className={classes.header}>
              {col.toUpperCase()}
            </TableCell>
          ) : (
            <TableCell align="center" key={col} className={classes.header}>
              {col.toUpperCase()}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

const CreateRows = ({ rows }) => {
  const classes = useStyles();
  let rowComponents = rows.map((row, i) => (
    <TableRow key={row.name}>
      <TableCell align="left">{row.name || "-"}</TableCell>
      <TableCell align="center">{row.length_ || "-"}</TableCell>
      <TableCell align="center">
        {row.value ? `$${numberFormatter(row.value)}` : "-"}
      </TableCell>
      <TableCell align="center">
        {row.avg ? `$${numberFormatter(row.avg)}` : "-"}
      </TableCell>
      <TableCell align="center">{row.age || "-"}</TableCell>
      {row.playerId ? (
        <TableCell align="center">
          <a href={`/contract/${row.playerId}`}>View</a>
        </TableCell>
      ) : (
        <TableCell align="center"></TableCell>
      )}
    </TableRow>
  ));

  let length = 0;
  let value = 0;
  let avgSalary = 0;
  let age = 0;

  rows.forEach((r) => {
    length += r.length_ ? parseInt(r.length_) : 0;
    value += r.value ? parseInt(r.value) : 0;
    avgSalary += r.avg ? parseInt(r.avg) : 0;
    age += r.age ? parseInt(r.age) : 0;
  });

  rowComponents.push(
    <TableRow className={classes.averagesRow} key={"-1"}>
      <TableCell align="left" style={{ fontWeight: 600 }}>
        Averages
      </TableCell>
      <TableCell align="center" style={{ fontWeight: 600 }}>
        {length / rows.length}
      </TableCell>
      <TableCell align="center" style={{ fontWeight: 600 }}>
        ${numberFormatter(Math.round(value / rows.length))}
      </TableCell>
      <TableCell align="center" style={{ fontWeight: 600 }}>
        ${numberFormatter(Math.round(avgSalary / rows.length))}
      </TableCell>
      <TableCell align="center" style={{ fontWeight: 600 }}>
        -
      </TableCell>
      <TableCell align="center" style={{ fontWeight: 600 }}></TableCell>
    </TableRow>
  );
  return rowComponents;
};

const CompareContractsTable = ({ user }) => {
  const classes = useStyles();
  const comparableContracts = useMemo(
    () => user?.marketValue?.comparablePlayers,
    [user]
  );

  const mapRows = () =>
    (comparableContracts || []).map((c) => {
      return {
        name: c.name,
        length_: c.contractLength,
        value: c.contractValue,
        avg: c.contractAverageValue,
        age: c.ageSigned,
        playerId: c.spotracId,
      };
    });

  if (comparableContracts?.length > 0)
    return (
      <div className={classes.container}>
        <TableContainer component={Paper} elevation={4}>
          <Table className={classes.table} aria-label="simple table">
            <CreateHeader columns={headers} />
            <TableBody>
              <CreateRows rows={mapRows()} />
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
};

export default CompareContractsTable;

const useStyles = makeStyles({
  container: {
    margin: "32px auto",
  },
  table: {
    minWidth: 650,
  },
  header: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  cell: {
    color: "#05070B",
    fontSize: "14px",
    ...setStyles.sofiaProRegular,
  },
  dash: {
    color: "#05070B",
    fontSize: "11px",
    ...setStyles.sofiaProRegular,
    opacity: 0.5,
  },
  averagesRow: {
    backgroundColor: "rgba(233, 203, 147, 0.2)",
  },
});
