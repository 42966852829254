import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Autocomplete } from '@material-ui/lab';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GeocoderInput({ onChange, onSelect, required }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const handleSelect = async (streetAddress) => {

    if (streetAddress) {
      const results = await geocodeByAddress(streetAddress);
      const latLng = await getLatLng(results[0]);
      const addressObj = results[0].address_components.reduce(
        (acc, component) => {
          const key = component.types[0];
          acc[key] = component.long_name;
          return acc;
        },
        {}
      );

      onSelect({ ...addressObj, ...latLng });
    }
    else {
      onSelect(null);
    }
  }

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDRJnqZH494BZ5Y_o-MGouwTSfLVjIe7P0&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetchAddresses = React.useMemo(() => {
    let timeoutId;
    return (request, callback) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200);
    };
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchAddresses({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchAddresses]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      required
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue?.description);
        handleSelect(newValue?.description);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search Location" variant="outlined" fullWidth required={required} />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              {/* <LocationOnIcon className={classes.icon} /> */}
            </Grid>
            <Grid item xs>
              {option.description}
            </Grid>
          </Grid>
        );
      }}
    />
  )
}
