import { axiosHandler } from "../utils/utils";

export const requestClient = (data) => async (_, getState) => {
  try {
    const json = await axiosHandler({
      url: `request/request-client`,
      method: "POST",
      data,
      state: getState(),
    });
    return json.data;
  } catch (error) {
    throw error;
  }
};

export const requestProspect = (data) => async (_, getState) => {
  try {
    const json = await axiosHandler({
      url: `request/request-prospect`,
      method: "POST",
      data,
      state: getState(),
    });
    return json.data;
  } catch (error) {
    throw error;
  }
};
