import React from "react";

import IconLink2 from "./IconLink2";
import IconButton from "./IconButton1";

import closeIcon from "../../../images/close.svg";
import allStarIcon from "../../../images/allstar.svg";
import cbaIcon from "../../../images/cba.svg";
import leagueIcon from "../../../images/league.svg";
import scrubIcon from "../../../images/scrub.svg";
import servicesIcon from "../../../images/services.svg";
import settingsIcon from "../../../images/settings.svg";
import logoutIcon from "../../../images/logout.svg";
import notepadIcon from "../../../images/notepad.svg";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import { useDispatch } from "react-redux";
import { removeUser } from "../../../redux/user";
import { removeToken } from "../../../redux/auth";
import { useHistory } from "react-router-dom";
import { userTypes } from "../../../utils/constants";
import IconLink from "./IconLink";

const useStyles = makeStyles((theme) => ({
  menuShow: {
    display: "block",
    position: "absolute",
    top: 0,
    left: "25%",
    width: "339px",
    backgroundColor: "white",
    color: "black",
    zIndex: 9999,
    borderRadius: "0px 0px 21px 21px",
    overflow: "hidden",
    marginLeft: "15px",
    transition: "all 0.5s linear",
    paddingBottom: "12px",
    [theme.breakpoints.down("sm")]: {
      left: "auto",
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  menuHide: {
    display: "block",
    position: "absolute",
    top: 0,
    left: "25%",
    width: "339px",
    backgroundColor: "white",
    color: "black",
    zIndex: 9999,
    borderRadius: "0px 0px 21px 21px",
    overflow: "hidden",
    marginLeft: "15px",
    maxHeight: "0px",
    transition: "all 0.5s linear",
    height: "0px",
    [theme.breakpoints.down("sm")]: {
      left: "auto",
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  expandedMenuHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  expandedMenuHeading: {
    textAlign: "left",
    padding: "25px 20px 5px",
    color: "#05070b",
    fontSize: "12px",
    ...setStyles.sofiaProBold,
    opacity: "0.5",
  },
  img: {
    marginRight: "26px",
    marginTop: "25px",
    width: "20px",
    height: "20px",
    cursor: "pointer",
    ...setStyles.sofiaProBold,
  },
}));

const ExpandedMenu = ({ showMore, closeMenu, ...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = props;
  const dispatch = useDispatch();

  const onLogout = async () => {
    await dispatch(removeUser());
    await dispatch(removeToken());

    history.push("/login");
  };

  return (
    <div className={showMore ? classes.menuShow : classes.menuHide}>
      <div className={classes.expandedMenuHeadingContainer}>
        <p className={classes.expandedMenuHeading}>Navigation</p>
        <img
          src={closeIcon}
          alt=""
          onClick={closeMenu}
          className={classes.img}
        />
      </div>
      <div>
        <IconLink2
          text="CBA Interpretation"
          image={cbaIcon}
          url="/documents/CBA/2"
        />
        <IconLink2
          text="Professional Services"
          image={servicesIcon}
          url="/professionalservices"
        />
        <IconLink2
          text="League Contacts"
          image={leagueIcon}
          url="/leagueaccess"
        />
        <IconLink2
          text="Professional Development"
          image={scrubIcon}
          url="/professionaldevelopment"
        />
        {/* <IconLink2 text="Settings" image={settingsIcon} url="/settings" /> */}

        <IconLink2 text="Notepad" image={notepadIcon} url="/notepad" />
        <IconButton text="Logout" image={logoutIcon} onClick={onLogout} />
      </div>
    </div>
  );
};

export default ExpandedMenu;
