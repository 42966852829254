import React from "react";

import { Grid } from "@material-ui/core";

import arrow from "../../../images/arrow-right-white.svg";
import topLeftImg from "../../../images/card-status-top-left.png";
import bottomRightImg from "../../../images/card-status-bottom-right.png";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  statsGridItem: {
    width: "100%",
    maxWidth: "480px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto auto 20px",
    },
  },
  statsContainer: {
    margin: "auto",
    width: "480px",
    [theme.breakpoints.down("sm")]: {
      margin: "75px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  innerContainer: {
    height: "150px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    position: "relative",
  },
  topLeftImg: {
    position: "absolute",
    backgroundImage: `url(${topLeftImg})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "125px",
    height: "125px",
  },
  topRightImg: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${topLeftImg})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "125px",
    height: "125px",
    transform: "scaleX(-1)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    zIndex: 10,
  },
  h5a: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "14px",
  },
  h5b: {
    color: "#696A6D",
    ...setStyles.sofiaProBold,
    fontSize: "14px",
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "30px",
  },
}));

const BoxA = ({ avg }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={6} className={classes.statsGridItem}>
      <div className={classes.statsContainer}>
        <div className={classes.innerContainer}>
          <div className={classes.topLeftImg} />
          <div className={classes.topRightImg} />
          <div className={classes.content}>
            <h5 className={classes.h5a}>CALCULATED MARKET VALUE</h5>
            <h2 className={classes.h2}>{isNaN(avg) ? "N/A" : `$${avg}`}</h2>
            <h5 className={classes.h5b}>AVG. ANNUAL SALARY</h5>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default BoxA;
