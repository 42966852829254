import React, { useMemo } from "react";

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import numberFormatter from "../../../utils/numberFormatter";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchUser } from "../../../api/user";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "50px",
  },
  table: {
    minWidth: 650,
  },
  notesTable: {
    minWidth: 650,
    marginBottom: "24px",
    padding: "24px",
    "& h5": {
      color: "#A28C62",
      marginBottom: "12px",
      fontSize: "14px",
      ...setStyles.sofiaProBold,
    },
  },
  header: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  currYear: {
    backgroundColor: "rgba(233, 203, 147, 0.2)",
  },
  cell: {
    color: "#05070B",
    fontSize: "14px",
    ...setStyles.sofiaProRegular,
  },
  cellCash: {
    color: "#05070B",
    fontSize: "14px",

    ...setStyles.sofiaProBold,
  },
  dash: {
    color: "#05070B",
    fontSize: "11px",
    ...setStyles.sofiaProRegular,
    opacity: 0.5,
  },
}));

const headers = [
  "YEAR",
  "TEAM",
  "AGE",
  "BASE SALARY",
  "SIGNING BONUS",
  "CAP HIT",
  "DEAD CAP",
  "YEARLY CASH",
];

const CreateHeader = ({ columns }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, i) =>
          i === 0 ? (
            <TableCell align="left" key={col} className={classes.header}>
              {col.toUpperCase()}
            </TableCell>
          ) : (
            <TableCell align="center" key={col} className={classes.header}>
              {col.toUpperCase()}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

const CreateRows = ({ rows }) => {
  const classes = useStyles();

  const d = new Date();
  const y = d.getFullYear();

  let rowComponents = rows.map((row) => (
    <TableRow
      key={row.year}
      className={row.year == y ? classes.currYear : null}
    >
      <TableCell align="left" className={classes.cell}>
        {row.year}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {row.team}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {row.age}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        ${numberFormatter(row.base)}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        ${numberFormatter(row.bonus)}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        ${numberFormatter(row.capHit)}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        ${numberFormatter(row.deadCap)}
      </TableCell>
      <TableCell align="center" className={classes.cellCash}>
        ${numberFormatter(row.yearly)}
      </TableCell>
    </TableRow>
  ));

  // rowComponents.push(
  //   <TableRow key={rows[rows.length - 1].year + 1}>
  //     <TableCell align="left" className={classes.cell}>
  //       {rows[rows.length - 1].year + 1}
  //     </TableCell>
  //     <TableCell align="center" className={classes.cell}>
  //       {rows[rows.length - 1].team}
  //     </TableCell>
  //     <TableCell align="center" className={classes.cell}>
  //       {rows[rows.length - 1].age + 1}
  //     </TableCell>
  //     <TableCell align="center">
  //       <div
  //         style={{
  //           backgroundColor: "#3F6EDE",
  //           width: "55px",
  //           height: "29px",
  //           borderRadius: "4px",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           margin: "auto",
  //         }}
  //       >
  //         <span
  //           style={{
  //             fontSize: "16px",
  //             ...setStyles.sofiaProBold,
  //             color: "#FFFFFF",
  //             opacity: 0.9,
  //           }}
  //         >
  //           UFA
  //         </span>
  //       </div>
  //     </TableCell>
  //     <TableCell align="center" className={classes.dash}>
  //       -
  //     </TableCell>
  //     <TableCell align="center" className={classes.dash}>
  //       -
  //     </TableCell>
  //     <TableCell align="center" className={classes.dash}>
  //       -
  //     </TableCell>
  //     <TableCell align="center" className={classes.dash}>
  //       -
  //     </TableCell>
  //   </TableRow>
  // );
  return rowComponents;
};

const ContractDetailsTable = ({ selected, user }) => {
  const classes = useStyles();

  const contract = useMemo(() => selected || user?.contract, [selected, user]);

  const mapRows = (contract?.breakdowns || []).map((c) => {
    return {
      year: c?.year,
      team: `${c?.team?.city} ${c?.team?.name}` || "-",
      age: c?.age > 0 ? c?.age : "-",
      base: c?.baseSalary,
      bonus: c?.signingBonus,
      deadCap: c?.deadCap,
      capHit: c?.capHit,
      yearly: c?.yearlyCash,
    };
  });

  if (!contract) return null;

  return (
    <div className={classes.container}>
      {contract?.notes && (
        <TableContainer
          component={Paper}
          elevation={4}
          className={classes.notesTable}
        >
          <h5>CONTRACT NOTES</h5>
          <div dangerouslySetInnerHTML={{ __html: contract.notes }} />
        </TableContainer>
      )}
      {mapRows.length > 0 && (
        <TableContainer component={Paper} elevation={4}>
          <Table className={classes.table} aria-label="simple table">
            <CreateHeader columns={headers} />
            <TableBody>
              <CreateRows rows={mapRows} />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ContractDetailsTable;
