import { createToken, removeToken } from "../redux/auth";
import { loadUser } from "../redux/user";
import { axiosHandler } from "../utils/utils";

export const login = (data) => async (dispatch) => {
  try {
    verifyLogin(data);
    const json = await axiosHandler({
      url: `auth/login`,
      method: "POST",
      data,
    });
    await Promise.all([
      dispatch(createToken(json.data?.access_token)),
      dispatch(loadUser(json.data?.user)),
    ]);
  } catch (error) {
    await dispatch(removeToken());
    throw error;
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    verifyEmail(data);
    const json = await axiosHandler({
      url: `auth/forgot-password`,
      method: "POST",
      data,
    });
    return json;
  } catch (error) {
    await dispatch(removeToken());
    throw error;
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    verifyPassword(data);
    const json = await axiosHandler({
      url: `auth/password`,
      method: "POST",
      data,
    });
    return json;
  } catch (error) {
    await dispatch(removeToken());
    throw error;
  }
};

// Validation Body
function verifyLogin(data) {
  const { email, password } = data;
  const errors = [];
  if (!email) errors.push("Email not provided");
  if (!password) errors.push("Password not provided");
  if (errors.length) throw Error(errors.join("\n"));
}

function verifyEmail(data) {
  const { email } = data;
  const errors = [];
  if (!email) errors.push("Email not provided");
}

function verifyPassword(data) {
  const { password, confirmPassword, code } = data;
  const errors = [];
  if (!password) errors.push("Password not provided");
  if (!confirmPassword) errors.push("Confirm Password not provided");
  if (!code) errors.push("Password Reset Code Not Found");
  if (password !== confirmPassword) errors.push("Passwords do not match");
  if (errors.length) throw Error(errors.join("\n"));
}
