import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import numberFormatter from "../../../utils/numberFormatter";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchUser } from "../../../api/user";
import { userTypes } from "../../../utils/constants";

const ContractInfo = ({ selected, user }) => {
  const classes = useStyles();
  const contract = useMemo(() => selected || user?.contract, [selected, user]);

  return (
    <>
      {((user?.userType === userTypes.PLAYER && user?.spotracId) ||
        selected) && (
        <Grid container direction="column" className={classes.container}>
          <div className={classes.row} style={{ marginBottom: "24px" }}>
            {contract?.totalSalary && (
              <div className={classes.column}>
                <h5>CONTRACT TERMS</h5>
                <h1 className={classes.terms}>
                  ${numberFormatter(contract?.totalSalary)} / {contract?.years}{" "}
                  YR.
                </h1>
              </div>
            )}
            <div className={classes.column}>
              <h5>SIGNED/FREE AGENT</h5>
              <h3 className={classes.agent}>
                {`${contract?.startYear} / ${contract?.endYear}`}
              </h3>
            </div>
          </div>
          {/* <div className={classes.row}>
        <div className={classes.column}>
          <h5>SIGNING BONUS</h5>
          <h3 className={classes.h3a}>
            ${numberFormatter(contractBreakdown[0].cashSigning)}
          </h3>
        </div>
        <div className={classes.column}>
          <h5>AVG. SALARY</h5>
          <h3 className={classes.h3a}>
            ${numberFormatter(contractInfo.average)}
          </h3>
        </div>
        <div className={classes.column}>
          <h5>GTD AT SIGN</h5>
          <h3 className={classes.h3a}>
            ${numberFormatter(contractInfo.average)}**
          </h3>
        </div>
        <div className={classes.column}>
          <h5>TOTAL GTD</h5>
          <h3 className={classes.h3a}>
            ${numberFormatter(contractInfo.average)}**
          </h3>
        </div>
      </div> */}
        </Grid>
      )}
      {(user?.userType === userTypes.EXECUTIVE ||
        user?.userType === userTypes.COACH) && (
        <Grid container direction="column" className={classes.container}>
          <div className={classes.row} style={{ marginBottom: "24px" }}>
            <div className={classes.column}>
              <h5>NEXT PAYCHECK</h5>
              <h1 className={classes.terms}>
                $
                {numberFormatter(user?.executive?.nextPaycheck) ||
                  numberFormatter(user?.coach?.nextPaycheck) ||
                  "NA"}{" "}
              </h1>
            </div>
            <div className={classes.column}>
              <h5>{`${
                user?.executive ? "EXECUTIVE" : user?.coach ? "COACH" : "NA"
              } STATUS`}</h5>
              <h3 className={classes.agent}>
                {`${
                  user?.executive?.scoutType || user?.coach?.coachType || "NA"
                } - ${
                  (user?.executive && String(user.executive.yearsActive)) ||
                  (user?.coach && String(user.coach.yearsActive))
                } YRS.`}
              </h3>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default ContractInfo;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "55px 0px 60px",
    maxWidth: "870px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      width: "90%",
      maxWidth: "460px",
      margin: "auto",
      textAlign: "center",
    },
  },
  column: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  terms: {
    fontSize: "60px",
    ...setStyles.sofiaProBold,
    color: "#A28C62",
    letterSpacing: "-0.6px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  agent: {
    fontSize: "30px",
    ...setStyles.sofiaProRegular,
    color: "#3F6EDE",
    letterSpacing: "-0.18px",
  },
  h3: {
    fontSize: "30px",
    ...setStyles.sofiaProRegular,
    color: "#404040",
    letterSpacing: "-0.18px",
    opacity: 0.5,
  },
  h3a: {
    fontSize: "30px",
    ...setStyles.sofiaProRegular,
    color: "#404040",
    letterSpacing: "-0.18px",
    opacity: 0.5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
}));
