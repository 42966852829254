import { axiosHandler } from "../lib/utils";

export const saveCommissionData = (data) => async (_, getState) => {
  try {
    const json = await axiosHandler({
      url: `commission-calculator/save`,
      method: "POST",
      data,
      state: getState(),
    });
    return json.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchCommissionData = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `commission-calculator`,
      method: "GET",
      state: getState(),
    });
    return json.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
