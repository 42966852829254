import React from "react";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "auto",
    height: "50px",
    margin: "auto",
    borderRadius: "25px",
    backgroundColor: "white",
    boxShadow: "0px 2px 8px #00000040",
    padding: "0px 2px",
  },
  container2: {
    width: "209.5px",
    height: "50px",
    margin: "auto",
    borderRadius: "25px",
    backgroundColor: "white",
    boxShadow: "0px 2px 8px #00000040",
    padding: "0px 2px",
  },
  h3: {
    fontSize: "20px",
    padding: "7px 27px",
    cursor: "pointer",
    ...setStyles.sofiaProBold,
  },
  h3Active: {
    fontSize: "20px",
    background: "rgba(255, 0, 43, 0.2)",
    padding: "7px 27px",
    borderRadius: "25px",
    color: "#FF002B",
    cursor: "pointer",
    ...setStyles.sofiaProBold,
  },
}));

const TabsNav = ({ labels, activeTab, selectTab }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={labels.length > 1 ? classes.container : classes.container2}
    >
      {labels.map((label) => (
        <h3
          className={label === activeTab ? classes.h3Active : classes.h3}
          onClick={() => selectTab(label)}
          key={label}
        >
          {label}
        </h3>
      ))}
    </Grid>
  );
};

export default TabsNav;
