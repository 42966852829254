import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import StatsA from "../Standalones/StatsA";
import StatsB from "../Standalones/StatsB";
import StatsC from "../Standalones/StatsC";
import StatsD from "../Standalones/StatsD";
import ExpandableSection from "../Standalones/ExpandableSection";
import AllUsers from "../Standalones/AllUsers";
import { useDispatch } from "react-redux";
import { requestClient, requestProspect } from "../../api/request";
import { Alert, AlertTitle } from "@material-ui/lab";
import Arrow from "../../images/Icons/Arrow";
import {
  userTypes,
  playerTypes,
  playerStatusTypes,
  collegePlayerTypes,
} from "../../utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import { upperFirstLetter } from "../../lib/utils";
import setStyles from "../../setStyles";
import CommissionCalculator from "../../screens/CommissionCalculator";
const channelId = "C076ZB13H0W";
const teamId = "T073K9R0KAP";
const useStyles = makeStyles((theme) => ({
  container1: {
    maxWidth: "480px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "90%",
      marginTop: "-60px",
    },
  },
  arrow: {
    paddingLeft: "16px",
  },
  container2: {
    // height: "321px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  contractContainer1: {
    margin: "30px auto auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      height: "100%",
      width: "90%",
    },
  },
  contractContainer2: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  gridContainer: {
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  link: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
  },
  CBAConnectContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#43184720",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
    marginTop: "30px",
  },
  CBAConnect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    color: "#431847",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
  },
  CBAConnectContainer2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#3867D620",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
    marginTop: "30px",
  },
  CBAConnect2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    color: "#3867D6",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
  },
  requestButton: {
    display: "inline-flex",
    alignItems: "center",
    height: "50px",
    paddingRight: "24px",
    paddingLeft: "24px",
    color: "#4B7BEC",
    backgroundColor: "#4B7BEC20",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
    marginTop: "30px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const calculatePercentage = (value, percentages) => {
  if (value === null || value === undefined || value === "" || value < 0) {
    return "N/A";
  }
  return value === 0 ? 0 : Math.min(percentages[value - 1] || 100, 100);
};

const StatsContainer = ({ contract2, selected, user, parent, page }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProspect, setIsOpenProspect] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [teamSchool, setTeamSchool] = useState("");
  const [position, setPosition] = useState("");
  const [userType, setUserType] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contract = selected || user?.contract;
  const isContract =
    contract ||
    user?.userType !== userTypes.PLAYER ||
    user?.userType !== userTypes.COLLEGE
      ? true
      : false;

  const handleCloseDialog = () => {
    setIsOpen(false);
    setIsOpenProspect(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await dispatch(
        requestClient({
          playerName,
          teamSchool,
          position,
          type,
          userType,
          status,
        })
      );
      setIsSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  const handleSubmiProspect = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await dispatch(
        requestProspect({
          playerName,
          teamSchool,
          position,
          type,
          userType,
          status,
        })
      );
      setIsSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  return (
    <div
      className={contract2 ? classes.container1 : classes.contractContainer1}
      style={{
        margin: contract2 ? "30px auto auto" : undefined,
        width: contract2 ? "100%" : undefined,
        maxWidth: user?.userType === userTypes.AGENT && page ? "100%" : "auto",
      }}
    >
      {/* <a href="/contract" className={classes.link}> */}
      {(user?.userType === userTypes.PLAYER ||
        user?.userType === userTypes.COLLEGE ||
        selected) && (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={
            isContract ? classes.container2 : classes.contractContainer2
          }
        >
          <div style={{ marginBottom: "30px" }}>
            {isContract && (
              <StatsA user={user} selected={selected} parent={parent} />
            )}
          </div>
          {user?.userType === userTypes.PLAYER && (
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                className={false && classes.gridContainer}
              >
                <StatsB
                  numSeas={contract?.totalCredited || 0}
                  titleA="Credited Seasons"
                  titleB="Credited Games"
                  perc={
                    contract?.currentCredited
                      ? calculatePercentage(
                          contract?.currentCredited,
                          [33, 67, 100]
                        )
                      : "0"
                  }
                  color="teal"
                  user={user}
                />
                <StatsB
                  numSeas={contract?.totalAccrued || 0}
                  titleA="Accrued Seasons"
                  titleB="Accrued Games"
                  perc={
                    contract?.currentAccrued
                      ? calculatePercentage(
                          contract?.currentAccrued,
                          [17, 33, 50, 67, 83, 100]
                        )
                      : "0"
                  }
                  color="gold"
                  user={user}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {user?.userType !== userTypes.PLAYER &&
        user?.userType !== userTypes.COLLEGE &&
        !selected &&
        user?.userType !== userTypes.PARENT &&
        user?.userType !== userTypes.SPOUSE &&
        user?.userType !== userTypes.AGENT && (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            className={
              isContract ? classes.container2 : classes.contractContainer2
            }
          >
            <StatsD user={user} />
            <Grid item>
              {user?.userType !== userTypes.AGENT && (
                <Grid
                  container
                  justifyContent="space-between"
                  className={false && classes.gridContainer}
                >
                  <StatsC
                    numSeas={
                      (user?.executive &&
                        `${user.executive.yearsWithCurrentTeam}`) ||
                      (user?.coach && `${user.coach.yearsWithCurrentTeam}`) ||
                      (user?.reporter &&
                        `${user.reporter.yearsWithCurrentTeam}`)
                    }
                    titleA={`Years with ${
                      (user?.team?.city &&
                        user?.team.name &&
                        user?.team?.city + " " + user?.team?.name) ||
                      "Team"
                    }`}
                    color="teal"
                    user={user}
                  />
                  <StatsC
                    numSeas={
                      (user?.executive && `${user.executive.yearsActive}`) ||
                      (user?.coach && `${user.coach.yearsActive}`) ||
                      (user?.reporter && `${user.reporter.yearsActive}`)
                    }
                    titleA={
                      user?.userType === userTypes.EXECUTIVE
                        ? `Years Scouting`
                        : user?.userType === userTypes.REPORTER
                        ? `Years Reporting`
                        : `Years ${upperFirstLetter(user?.userType)}ing`
                    }
                    color="gold"
                    user={user}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      {user?.userType === userTypes.PARENT && (
        <ExpandableSection placeholder="My Players">
          <AllUsers selected={true} fullWidth={false} />
        </ExpandableSection>
      )}
      {user?.userType === userTypes.SPOUSE && (
        <ExpandableSection placeholder="My Spouse">
          <AllUsers selected={true} fullWidth={false} />
        </ExpandableSection>
      )}
      {user?.userType === userTypes.AGENT && (
        <Grid container direction="column" alignItems="center">
          <StatsD user={user} />
          <div style={{ width: "100%" }}>
            <ExpandableSection placeholder="My Clients" showFilters={true}>
              <AllUsers selected={true} fullWidth={page ? true : false} />
              {user?.userType === userTypes.AGENT ? (
                <div className={classes.buttonContainer}>
                  <button
                    type="button"
                    className={classes.requestButton}
                    onClick={() => setIsOpen(true)}
                  >
                    <span>Request a Client</span>
                    <div className={classes.arrow}>
                      <Arrow color="blue" />
                    </div>
                  </button>
                </div>
              ) : null}
            </ExpandableSection>
          </div>
          <div style={{ width: "100%" }}>
            <ExpandableSection placeholder="My Prospects" showFilters={true}>
              <AllUsers
                selected={true}
                fullWidth={page ? true : false}
                isProspects={true}
              />
              {user?.userType === userTypes.AGENT ? (
                <div className={classes.buttonContainer}>
                  <button
                    type="button"
                    className={classes.requestButton}
                    onClick={() => setIsOpenProspect(true)}
                  >
                    <span>Request a Prospect</span>
                    <div className={classes.arrow}>
                      <Arrow color="blue" />
                    </div>
                  </button>
                </div>
              ) : null}
            </ExpandableSection>
          </div>
          {page && (
            <div style={{ width: "100%" }}>
              <ExpandableSection placeholder="All Players">
                <AllUsers selected={false} fullWidth={true} />
              </ExpandableSection>
            </div>
          )}
          {page && (
            <div style={{ width: "100%" }}>
              <ExpandableSection placeholder="Commission Calculator">
                <CommissionCalculator />
              </ExpandableSection>
            </div>
          )}
        </Grid>
      )}

      <div
        className={classes.CBAConnectContainer}
        style={{ marginBottom: "48px" }}
      >
        <a
          href="https://join.slack.com/t/straticathlete/shared_invite/zt-2yv2ll4pu-aGn8nY5luwdvfKGYsP9Yqw"
          className={classes.CBAConnect}
        >
          Connect and Collaborate Live
          <div className={classes.arrow}>
            <Arrow color="slack" />
          </div>
        </a>
      </div>

      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        TransitionProps={{
          onExited: () => {
            setPlayerName("");
            setTeamSchool("");
            setPosition("");
            setType("");
            setUserType("");
            setStatus("");
            setIsSuccess(false);
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Request a Client</DialogTitle>
        <DialogContent>
          {isSuccess ? (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your client request was successful.
            </Alert>
          ) : (
            <>
              <TextField
                label="Player Name"
                variant="outlined"
                defaultValue={playerName}
                onChange={(e) => {
                  setPlayerName(e.target.value);
                }}
                fullWidth
                autoFocus
                required
                style={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Team/School"
                variant="outlined"
                defaultValue={teamSchool}
                onChange={(e) => {
                  setTeamSchool(e.target.value);
                }}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
              >
                <InputLabel>Type</InputLabel>
                <Select
                  value={userType}
                  onChange={(e) => {
                    const newUserType = e.target.value;
                    setUserType(newUserType);
                    setType("");
                  }}
                  label="Type"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={userTypes.COLLEGE}>College Player</MenuItem>
                  <MenuItem value={userTypes.PLAYER}>
                    Professional Player
                  </MenuItem>
                </Select>
              </FormControl>
              {userType && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "1rem" }}
                >
                  <InputLabel>Player Type</InputLabel>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    label="Player Type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.values(
                      userType === userTypes.PLAYER
                        ? playerTypes
                        : collegePlayerTypes
                    ).map((playerType) => (
                      <MenuItem key={playerType} value={playerType}>
                        {playerType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.values(playerStatusTypes)
                    .filter(
                      (s) =>
                        s !== playerStatusTypes.DRAFT_PROSPECT &&
                        s !== playerStatusTypes.PROSPECT &&
                        s !== playerStatusTypes.FREE_AGENT
                    )
                    .map((statusType) => (
                      <MenuItem key={statusType} value={statusType}>
                        {statusType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label="Position"
                variant="outlined"
                defaultValue={position}
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {isSuccess ? (
            <Button
              onClick={handleCloseDialog}
              color="primary"
              size="large"
              autoFocus
              style={{
                textTransform: "unset",
                fontWeight: "bold",
              }}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={handleCloseDialog}
                color="primary"
                size="large"
                autoFocus
                style={{
                  textTransform: "unset",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                onClick={handleSubmit}
                color="secondary"
                size="large"
                style={{
                  textTransform: "unset",
                  fontWeight: "bold",
                }}
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenProspect}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        TransitionProps={{
          onExited: () => {
            setPlayerName("");
            setTeamSchool("");
            setPosition("");
            setType("");
            setUserType("");
            setStatus("");
            setIsSuccess(false);
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Request a Prospect</DialogTitle>
        <DialogContent>
          {isSuccess ? (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your prospect request was successful.
            </Alert>
          ) : (
            <>
              <TextField
                label="Player Name"
                variant="outlined"
                defaultValue={playerName}
                onChange={(e) => {
                  setPlayerName(e.target.value);
                }}
                fullWidth
                autoFocus
                required
                style={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Team/School"
                variant="outlined"
                defaultValue={teamSchool}
                onChange={(e) => {
                  setTeamSchool(e.target.value);
                }}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
              >
                <InputLabel>Type</InputLabel>
                <Select
                  value={userType}
                  onChange={(e) => {
                    const newUserType = e.target.value;
                    setUserType(newUserType);
                    setType("");
                  }}
                  label="Type"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={userTypes.COLLEGE}>College Player</MenuItem>
                  <MenuItem value={userTypes.PLAYER}>
                    Professional Player
                  </MenuItem>
                </Select>
              </FormControl>
              {userType && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "1rem" }}
                >
                  <InputLabel>Player Type</InputLabel>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    label="Player Type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.values(
                      userType === userTypes.PLAYER
                        ? playerTypes
                        : collegePlayerTypes
                    ).map((playerType) => (
                      <MenuItem key={playerType} value={playerType}>
                        {playerType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "1rem" }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.values(playerStatusTypes)
                    .filter(
                      (s) =>
                        s !== playerStatusTypes.DRAFT_PROSPECT &&
                        s !== playerStatusTypes.PROSPECT &&
                        s !== playerStatusTypes.FREE_AGENT
                    )
                    .map((statusType) => (
                      <MenuItem key={statusType} value={statusType}>
                        {statusType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label="Position"
                variant="outlined"
                defaultValue={position}
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {isSuccess ? (
            <Button
              onClick={handleCloseDialog}
              color="primary"
              size="large"
              autoFocus
              style={{
                textTransform: "unset",
                fontWeight: "bold",
              }}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={handleCloseDialog}
                color="primary"
                size="large"
                autoFocus
                style={{
                  textTransform: "unset",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                onClick={handleSubmiProspect}
                color="secondary"
                size="large"
                style={{
                  textTransform: "unset",
                  fontWeight: "bold",
                }}
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatsContainer;
