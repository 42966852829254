import React, { useState } from "react";
import { Typography, IconButton, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";

const ExpandableSection = ({ placeholder, children }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper elevation={3} className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography
            variant="h6"
            className={classes.placeholder}
            onClick={toggleExpand}
          >
            {placeholder}
          </Typography>
        </div>
        <IconButton onClick={toggleExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      {expanded && <div className={classes.content}>{children}</div>}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    margin: theme.spacing(4, 2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  placeholder: {
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

export default ExpandableSection;
