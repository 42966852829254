import React, { useMemo } from "react";

import StatsContainer from "../../Dashboard/StatsContainer";
import ContractInfo from "./ContractInfo";
import ContractDetailsTable from "./ContractDetailsTable";
import ContractSpotracSalaryChart from "./ContractSpotracSalaryChart";
import { userTypes } from "../../../utils/constants";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchPlayerDeals } from "../../../api/playerDeal";
import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import setStyles from "../../../setStyles";
import CommissionCalculator from "../../../screens/CommissionCalculator";
import Arrow from "../../../images/Icons/Arrow";
import { isCollegePlayer, isPlayer } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  cell: {
    color: "#05070B",
    fontSize: "14px",
    ...setStyles.sofiaProRegular,
  },
  arrow: {
    paddingLeft: "16px",
  },
  CBAConnectContainer2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#3867D620",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
    marginTop: "30px",
  },
  CBAConnect2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    color: "#3867D6",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
  },
}));

const formatMoney = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const DetailsContainer = ({ selected, user, parent, page }) => {
  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        // display: "flex",
        marginTop: "60px",
      }}
    >
      <StatsContainer
        contract2={true}
        selected={selected}
        user={user}
        parent={parent}
        page={page}
      />
      <ContractInfo selected={selected} user={user} />
      {(user?.userType === userTypes.PLAYER ||
        user?.userType === userTypes.COLLEGE ||
        selected) && [
        <ContractDetailsTable selected={selected} user={user} key={"1"} />,
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <ContractSpotracSalaryChart user={user} selected={selected} />
        </div>,
      ]}
    </div>
  );
};

export const DealsTable = ({ user, userType }) => {
  console.log("DealsTable - userType", userType);
  console.log("DealsTable - userType", user.userType);
  const classes = useStyles();

  const { loadingPlayerDeals, playerDeals } = useReduxQuery("PlayerDeals", () =>
    fetchPlayerDeals(user.id)
  );

  const deals = Object.values(playerDeals || {}).filter(
    (pd) => Number(pd.userId) === Number(user.id)
  );

  const totalWeek = deals.reduce((acc, deal) => acc + Number(deal.duration), 0);
  const totalWeeklyPay = deals.reduce((acc, deal) => acc + deal.weeklyPay, 0);
  const totalValue = deals.reduce((acc, deal) => acc + deal.totalValue, 0);

  const dealValueUrl = useMemo(() => {
    let url = "/deal-value";
    if (userType === userTypes.AGENT) url += `?playerId=${user?.id}`;
    return url;
  }, [userType, user]);

  return (
    <div
      style={{
        marginTop: "48px",
        marginBottom: "48px",
      }}
    >
      <h5 style={{ textTransform: "uppercase" }}>Marketing Deals</h5>
      <Paper
        elevation={4}
        style={{
          marginTop: "24px",
        }}
      >
        {loadingPlayerDeals ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
            }}
          >
            <CircularProgress />
          </div>
        ) : deals.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header} component="th">
                      Company
                    </TableCell>
                    <TableCell className={classes.header} component="th">
                      Deal Length
                    </TableCell>
                    <TableCell className={classes.header} component="th">
                      Total Value
                    </TableCell>
                    <TableCell className={classes.header} component="th">
                      Weekly Pay
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deals.map((deal) => (
                    <TableRow key={deal.id}>
                      <TableCell className={classes.cell}>
                        {deal.company}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {deal.duration}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isNaN(deal.totalValue)
                          ? "-"
                          : `${formatMoney(deal.totalValue)}`}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isNaN(deal.weeklyPay)
                          ? "-"
                          : `${formatMoney(deal.weeklyPay)}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "24px",
            }}
          >
            <p>No deals available</p>
          </div>
        )}
      </Paper>
      {deals.length > 1 && (
        <Paper elevation={4} style={{ marginTop: "24px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} component="th">
                    Total Weeks
                  </TableCell>
                  <TableCell className={classes.header} component="th">
                    Total Value
                  </TableCell>
                  <TableCell className={classes.header} component="th">
                    Total Weekly Pay
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>
                    {isNaN(totalWeek) ? "-" : `${totalWeek} weeks`}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {isNaN(totalValue) ? "-" : `${formatMoney(totalValue)}`}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {isNaN(totalWeeklyPay)
                      ? "-"
                      : `${formatMoney(totalWeeklyPay)}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {isCollegePlayer(user.userType) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "48px",
          }}
        >
          <div className={classes.CBAConnectContainer2}>
            <a href={dealValueUrl} className={classes.CBAConnect2}>
              {`${
                isPlayer(userType)
                  ? "What are my deals worth?"
                  : "What are their deals worth?"
              }`}
              <div className={classes.arrow}>
                <Arrow color="blue" />
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsContainer;
