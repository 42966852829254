import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { saveCommissionData, fetchCommissionData } from "../api/commission";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "16px",
  },
  p: {
    color: "#404040",
    opacity: 0.8,
    ...setStyles.sofiaProRegular,
    fontSize: "18px",
  },
}));

const defaultValues = {
  players: [
    {
      playerName: "",
      salary: "",
      percentage: "",
      commission: "",
    },
  ],
  avgSalary: "",
  avgPercent: "",
  totalCommission: "",
};

const cleanNumberInput = (value) => {
  if (!value) return 0;
  return parseFloat(value.toString().replace(/,/g, ""));
};

const formatMoney = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const formatPct = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
};

const formatNumberInput = (value) => {
  if (!value) return "";
  // Remove any non-digit characters except decimal point
  const cleanValue = value.toString().replace(/[^\d.]/g, "");
  // Format with commas
  const parts = cleanValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export default function CommissionCalculator() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { control, watch, setValue, getValues } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "players",
  });

  const calculateTotals = (players) => {
    const { salaries, percents, commissions } = players.reduce(
      (acc, p) => {
        acc.salaries.push(cleanNumberInput(p.salary));
        acc.percents.push(cleanNumberInput(p.percentage));
        acc.commissions.push(cleanNumberInput(p.commission));
        return acc;
      },
      {
        salaries: [],
        percents: [],
        commissions: [],
      }
    );

    setValue(
      "avgSalary",
      String(
        salaries.length
          ? salaries.reduce((x, y) => x + y, 0) / salaries.length
          : 0
      )
    );
    setValue(
      "avgPercent",
      String(
        percents.length
          ? percents.reduce((x, y) => x + y, 0) / percents.length
          : 0
      )
    );
    setValue("totalCommission", String(commissions.reduce((x, y) => x + y, 0)));
  };

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === "change") {
        value.players.forEach((player, index) => {
          const salary = cleanNumberInput(player.salary);
          const percentage = cleanNumberInput(player.percentage);
          const commission = salary * (percentage / 100);

          if (percentage && salary) {
            setValue(`players.${index}.commission`, commission);
          }
        });

        calculateTotals(value.players);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  // Load saved commission data on component mount
  useEffect(() => {
    const loadSavedData = async () => {
      try {
        const savedData = await dispatch(fetchCommissionData());
        if (savedData && savedData.players) {
          // Reset form with saved data
          savedData.players.forEach((player, index) => {
            if (index >= fields.length) {
              append({
                playerName: "",
                salary: "",
                percentage: "",
                commission: "",
              });
            }
          });

          // Set values after ensuring all fields exist
          savedData.players.forEach((player, index) => {
            setValue(`players.${index}.playerName`, player.playerName || "");
            setValue(
              `players.${index}.salary`,
              formatNumberInput(player.salary) || ""
            );
            setValue(`players.${index}.percentage`, player.percentage || "");
            setValue(`players.${index}.commission`, player.commission || "");
          });

          // Calculate totals
          calculateTotals(savedData.players);
        }
      } catch (error) {
        console.error("Error loading saved commission data:", error);
      }
    };

    loadSavedData();
  }, [dispatch, setValue, append, fields.length]);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const formData = getValues();

      // Format data for API
      const dataToSave = {
        players: formData.players.map((player) => ({
          playerName: player.playerName,
          salary: cleanNumberInput(player.salary),
          percentage: cleanNumberInput(player.percentage),
          commission: cleanNumberInput(player.commission),
        })),
        avgSalary: cleanNumberInput(formData.avgSalary),
        avgPercent: cleanNumberInput(formData.avgPercent),
        totalCommission: cleanNumberInput(formData.totalCommission),
      };

      await dispatch(saveCommissionData(dataToSave));
      setSaveMessage("Commission data saved successfully");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error saving commission data:", error);
      setSaveMessage("Error saving commission data");
      setOpenSnackbar(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.container} style={{ marginTop: "0px" }}>
      <h5 style={{ textTransform: "uppercase" }}>Commission Calculator</h5>
      <div>
        <div>
          {fields.map((field, index) => (
            <div
              key={field.id}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginTop: "16px",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <Controller
                  control={control}
                  name={`players.${index}.playerName`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Player"
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Controller
                  control={control}
                  name={`players.${index}.salary`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      inputMode="decimal"
                      label="Salary"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        const formattedValue = formatNumberInput(
                          e.target.value
                        );
                        onChange(formattedValue);
                      }}
                      value={value}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Controller
                  control={control}
                  name={`players.${index}.percentage`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      inputMode="decimal"
                      label="Percentage"
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Controller
                  control={control}
                  name={`players.${index}.commission`}
                  render={({ field: { value } }) => (
                    <TextField
                      inputMode="decimal"
                      label="Commission"
                      variant="outlined"
                      fullWidth
                      value={formatMoney(value)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </div>
              <IconButton
                color="secondary"
                onClick={() => {
                  remove(index);
                  const currentPlayers = getValues("players");
                  calculateTotals(currentPlayers);
                }}
                aria-label="remove"
                disabled={fields.length === 1}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </div>
          ))}

          <div>
            <Button
              color="primary"
              variant="contained"
              style={{
                marginTop: "16px",
                marginRight: "16px",
              }}
              onClick={() =>
                append({
                  playerName: "",
                  salary: "",
                  percentage: "",
                  commission: "",
                })
              }
            >
              Add Player
            </Button>
            <Button
              color="secondary"
              variant="contained"
              style={{
                marginTop: "16px",
              }}
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            marginTop: "32px",
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <Controller
              control={control}
              name="avgSalary"
              render={({ field: { value } }) => (
                <TextField
                  label="Avg Salary"
                  variant="outlined"
                  fullWidth
                  value={formatMoney(value)}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <Controller
              control={control}
              name="avgPercent"
              render={({ field: { value } }) => (
                <TextField
                  label="Avg Percent"
                  variant="outlined"
                  fullWidth
                  value={formatPct(value)}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <Controller
              control={control}
              name="totalCommission"
              render={({ field: { value } }) => (
                <TextField
                  label="Total Commission"
                  variant="outlined"
                  fullWidth
                  value={formatMoney(value)}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={saveMessage}
      />
    </div>
  );
}
