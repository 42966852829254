import React from "react";
import ContactCard from "../Standalones/ContactCard";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { parseLocation } from "../../utils/utils";

const TeamContainer = ({ team, contacts }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h3 className={classes.h3}>{team}</h3>
      <div className={classes.contacts}>
        {(contacts || []).map((c, i) => (
          <div className={classes.cardContainer} key={i}>
            <ContactCard
              id={c.id}
              name={`${c.firstName} ${c.lastName}`}
              team={`${c?.team?.city} ${c?.team?.name}`}
              title={c.role}
              phone={c.phone}
              email={c.email}
              service={c.services.join(", ")}
              location={parseLocation(c)}
              logoUrl={c.team?.logoUrl}
              lat={c.lat}
              lon={c.lon}
              companyName={c.companyName}
              role={c.role}
              contact={c}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamContainer;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    width: "100%",
    borderBottom: "2px solid rgba(155, 156, 157, .2)",
    padding: "40px 0px 50px",
  },
  h3: {
    color: "#05070B",
    fontSize: "30px",
    ...setStyles.sofiaProBold,
    letterSpacing: "-0.15px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contacts: {
    marginTop: "26px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignSelf: "center",
    gridGap: "1em",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "300px 300px",

      width: "100%",
      height: "auto",
      margin: "26px auto auto",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      width: "100%",
    },
  },
  cardContainer: {
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
