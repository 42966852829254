import React from "react";

import Carousel from "../Standalones/Carousel";

import Map from "../Standalones/Map";
import EventCardSmall from "../Standalones/EventCardSmall";
import Arrow from "../../images/Icons/Arrow";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  h2: {
    margin: "56px auto auto 0px",
    ...setStyles.sofiaProSemiBold,
    fontSize: "24px",
  },
  aContainer: {
    margin: "67px auto 75px",
    textAlign: "center",
    marginTop: "10px",
  },
  a: {
    color: "#FF002B",
    fontSize: "20px",
    ...setStyles.sofiaProSemiBold,
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
  },
  arrow: {
    transform: "scaleX(-1)",
    paddingLeft: "10px",
  },
}));

const RelatedEvents = ({ events, event }) => {
  const classes = useStyles();

  const getRelatedCoords = () => {
    if (events.length > 0) {
      return events.map((e) => [e.lat, e.lon]);
    } else {
      return [];
    }
  };

  const displayCarousel = () => {
    return (
      <Carousel>
        {events.map((event, i) => (
          <EventCardSmall
            heading={event.title}
            location={`${event?.city}, ${event?.state}`}
            date={event.startDate}
            tags={event.tags}
            id={event.id}
            index={i + 1}
            key={event.title}
          />
        ))}
      </Carousel>
    );
  };

  return (
    <div className={classes.divContainer}>
      <h2 className={classes.h2}>Other Upcoming Events</h2>
      {(parseFloat(event.lat) && parseFloat(event.lon)) 
        ? <Map relatedPins={getRelatedCoords()} thisPin={[event.lat, event.lon]} /> 
        : null }
      {events.length > 0 && displayCarousel()}
      {/* <div className={classes.aContainer}>
        <a className={classes.a} href="/events">
          <div className={classes.arrow}>
            <Arrow color="red" />
          </div>
          See all events
        </a>
      </div> */}
    </div>
  );
};

export default RelatedEvents;
