import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { submitPlayerDeal } from "../api/playerDeal";
import { useLocation } from "react-router-dom";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchIndividualUser } from "../api/user";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "16px",
  },
  p: {
    color: "#404040",
    opacity: 0.8,
    ...setStyles.sofiaProRegular,
    fontSize: "18px",
  },
}));

const defaultValues = {
  name: "",
  term: "",
  location: "",
  exclusivity: "",
  lastDeal: [
    {
      value: "",
    },
  ],
  twitterFollowers: "",
  instagramFollowers: "",
  tiktokFollowers: "",
  deliverables: "",
  stats: "",
  accolades: "",
  interest: "",
  additionalNotes: "",
};

export default function DealValue() {
  // Get playerId from query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const playerId = queryParams.get("playerId");

  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [userName, setUserName] = useState("");

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "lastDeal",
  });

  const onSubmit = async (data) => {
    try {
      const { lastDeal, ...rest } = data;
      setLoading(true);
      await dispatch(
        submitPlayerDeal({
          ...rest,
          playerId,
          lastDeal: lastDeal.map((ld) => ld.value),
        })
      );
      setSuccess(true);
      setLoading(false);
      window.scrollTo(0, 0);
      reset(defaultValues);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    playerId && getPrefilledUser(playerId);
  }, [playerId, setValue]);

  const getPrefilledUser = async (id) => {
    try {
      const user = await dispatch(fetchIndividualUser(id));
      setUserName(`${user?.firstName} ${user?.lastName}`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    setValue("player", userName);
  }, [userName, setValue]);

  // useEffect(() => {
  //   setValue(
  //     "player",
  //     `${individualUser?.firstName} ${individualUser?.lastName}`
  //   );
  // }, [individualUser]);

  const interestOptions = [
    {
      value: "Don't Care",
      label: "Don't Care",
    },
    {
      value: "If the Money is Good",
      label: "If the Money is Good",
    },
    {
      value: "I'm Interested",
      label: "I'm Interested",
    },
    {
      value: "I Really Like Them",
      label: "I Really Like Them",
    },
    {
      value: "Can't Pass it Up!",
      label: "Can't Pass it Up!",
    },
  ];

  const title = useMemo(
    () => (playerId ? "What's their deal worth?" : "What's your deal worth?"),
    [playerId]
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.h2}>{title}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success ? (
          <Alert
            severity="success"
            style={{
              marginBottom: "24px",
            }}
          >
            <AlertTitle>Success</AlertTitle>
            Your deal was submitted successfully.
          </Alert>
        ) : null}
        <div>
          {playerId && (
            <div
              style={{
                marginTop: "16px",
                marginBottom: "24px",
              }}
            >
              <Controller
                control={control}
                name="player"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label=""
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    disabled={true}
                  />
                )}
              />
            </div>
          )}
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Deal Name"
                  helperText="Enter the name of the deal"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="term"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Deal Term"
                  helperText="How long is the deal for?"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="location"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Location"
                  helperText="Enter the location of the deal"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="exclusivity"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Exclusivity"
                  helperText="Are they limited opportunities from other companies?"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            {fields.map((field, index) => (
              <div
                key={field.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "16px",
                }}
              >
                <div style={{ width: "12px" }}>
                  <span>{index + 1}.</span>
                </div>
                <Controller
                  control={control}
                  name={`lastDeal.${index}.value`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Value of Last Deal"
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value}
                      required
                    />
                  )}
                />
                <IconButton
                  color="secondary"
                  onClick={() => remove(index)}
                  aria-label="remove"
                  disabled={fields.length === 1}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              </div>
            ))}

            <div
              style={{
                paddingLeft: "20px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                style={{
                  marginTop: "16px",
                }}
                onClick={() => append({ value: "" })}
              >
                Add Deal
              </Button>
            </div>
            {/* <Controller
              control={control}
              name="lastDeal"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Value of Last Deal"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            /> */}
          </div>
          <Controller
            control={control}
            name="twitterFollowers"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Twitter Followers"
                variant="outlined"
                fullWidth
                onChange={onChange}
                value={value}
                required
                style={{
                  marginTop: "24px",
                }}
              />
            )}
          />
          <div>
            <Controller
              control={control}
              name="instagramFollowers"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Instagram Followers"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="tiktokFollowers"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="TikTok Followers"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="deliverables"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Deliverables"
                  helperText="What do they want you to do?"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="stats"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Stats"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="accolades"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Accolades"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, name } }) => (
                <FormControl
                  component="fieldset"
                  style={{
                    marginTop: "24px",
                  }}
                >
                  <FormLabel component="legend">
                    Interested in working with this brand?
                  </FormLabel>
                  <RadioGroup
                    aria-label="interest"
                    name={name}
                    onChange={onChange}
                    value={value}
                    row
                    required
                  >
                    {interestOptions.map((option) => (
                      <FormControlLabel
                        color="secondary"
                        key={option.value}
                        value={option.value}
                        label={option.label}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="additionalNotes"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Additional Notes"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px",
                  }}
                />
              )}
            />
          </div>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              marginTop: "24px",
            }}
            startIcon={
              loading ? <CircularProgress size="1rem" color="white" /> : null
            }
            disabled={loading}
          >
            {loading ? "Submitting" : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
}
