import React, { useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import Routes from "./Routes";

import "./App.css";
import { SnackbarProvider } from "notistack";
import { useLocation } from "react-router-dom";

const theme = createTheme();

// Google Analytics page tracking component
function GoogleAnalyticsPageTracker() {
  const location = useLocation();

  useEffect(() => {
    // Get the current page path
    const pagePath = location.pathname + location.search;
    // Get a readable page name from the path
    const pageName = getPageNameFromPath(location.pathname);

    // Send page view to Google Analytics with the correct page name
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: pagePath,
        page_title: pageName,
        page_location: window.location.href,
      });
      console.log(`Google Analytics: Tracked page view for ${pageName}`);
    }

    // Update document title with the page name
    document.title = `${pageName} - Stratic Athlete Management`;
  }, [location]);

  // Helper function to convert path to readable page name
  const getPageNameFromPath = (path) => {
    // Remove leading slash and split by remaining slashes
    const segments = path.replace(/^\//, "").split("/");

    // Handle empty path (home page)
    if (segments[0] === "") return "Home";

    // Handle specific routes with custom naming
    if (segments[0] === "player" && segments.length > 1) {
      return `Player Profile - ${segments[1]}`;
    }

    // Special case for professionaldevelopment
    if (segments[0] === "professionaldevelopment") {
      return "Professional Development";
    }
    // Special case for leagueaccess
    if (segments[0] === "leagueaccess") {
      return "League Access";
    }

    // For other routes, format each segment and join with dashes
    return segments
      .map((segment) => {
        // Convert camelCase to separate words (e.g., playerEducation -> Player Education)
        const formatted = segment
          // Insert a space before all uppercase letters that follow a lowercase letter or number
          .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
          // Capitalize the first letter of each word
          .replace(/\b\w/g, (char) => char.toUpperCase());

        return formatted;
      })
      .join(" - ");
  };

  return null; // This component doesn't render anything
}

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <div className="App" style={{ postition: "relative" }}>
        <MuiThemeProvider theme={theme}>
          <GoogleAnalyticsPageTracker />
          <Routes />
        </MuiThemeProvider>
      </div>
    </SnackbarProvider>
  );
}

export default App;
